import styled from "styled-components"

const DescriptionPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : "50px"};
  padding-top: 50px;
  background: ${({ bgColor }) => (bgColor ? bgColor : "var(--blue)")};
  margin-top: -60px;
  .title {
    font-size: calc(24px + (60 - 24) * ((100vw - 300px) / (1600 - 300)));
    text-transform: none;
    font-weight: 300;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: calc(17px + (24 - 17) * ((100vw - 300px) / (1600 - 300)));
    letter-spacing: 5px;
    line-height: 29px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin: 0 auto 25px auto;
    max-width: 800px;
  }
  p,
  div {
    max-width: 800px;
    margin: 10px auto;
    font-size: calc(17px + (21 - 17) * ((100vw - 300px) / (1600 - 300)));
    line-height: 29px;
    color: #ffffff;
    font-weight: 200;
    text-align: center;
    b {
      display: inline-block;
      margin-bottom:20px;
    }
  }
`
export default DescriptionPart
