import React from "react"
import styled from "styled-components"

const HelpSection = styled(({ className, children }) => {
    return <div className={className}>{children}</div>
  })`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    max-width: 800px;
    justify-content: center;
    align-items: center;
    padding: 70px 0;
    h4 {
      text-transform: uppercase;
      text-align: center;
      color: var(--blue);
    }
    p {
      font-size: 20px;
      line-height: 29px;
      color: #00578a;
      font-weight: 300;
      text-align: center;
    }
  `
  export default HelpSection
