import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import {useStaticQuery, graphql, Link} from "gatsby"
import SectionBox from "../components/SectionBox"
import SectionSeparator from "../components/SectionHorizontalSeparator"
import Anim from "../components/Anim"
import {StaticImage, getImage, GatsbyImage} from "gatsby-plugin-image"
import SectionBannerImage from "../components/SectionBanner"
import DescriptionPart from "../components/DescriptionPart"
import HelpSection from "../components/HelpSection"
import Button from "../components/Button"
import GlobalContext from "../components/GlobalContext/GlobalContext"

const localText = {
  sectionTitle: {
    sr: "",
    en: "This is what we do:"
  },
   boxInfo: {
     title1: {
       sr: "",
       en: "Implementation of International Safety Management (ISM) Code:"
     },
     title2: {
       sr: "",
       en: "Ship Security Assessment:"
     }
 },
  help: {
    text: {
      sr: "",
      en: "Please get in touch to discuss your needs and find right vessel for you."
    }
  }
}

const BoxInfo = styled(({ className, bgColor, lang, text, image }) => {
  return (
    <div className={className}>
      <div className="image">
        {/*<StaticImage src="../images/ismmiddlepic.jpg" alt="ismps" />*/}
        <GatsbyImage alt={"ism info box image"} image={image} />
      </div>
      <div className="text" dangerouslySetInnerHTML={{__html: text}}>
        {/*<h3>{localText.boxInfo.title1[lang]}</h3>*/}
        {/*<ul>*/}
        {/*  <li>Preparation of SMS documents for vessels and shore-based facilities;</li>*/}
        {/*  <li>Preparation of shore-based facilities and vessels for ISM certification;</li>*/}
        {/*  <li>Arrangement of class inspections of the company and vessels prior to issuance of Documents of Compliance (DOCs) for the company and Safety Management Certificates (SMCs) for the vessels;</li>*/}
        {/*  <li>Supply of convention documentation, nautical charts, instructions and procedures to the vessels with following validity control;</li>*/}
        {/*  <li>Control of conformity of the vessel’s with flag state and International Maritime </li>*/}
        {/*  <li>Organization’s requirements;</li>*/}
        {/*  <li>Preparation of other required documents including “Emergency Towing Procedure”, “Low Sulfur Fuel Use”, “Vessel’s Garbage Management Plan”, “Vessel’s Energy Efficiency Plan”, “Vessel’s Oil Spillage Emergency Plan”, “Lifesaving Appliances Maintenance Plan”, “Firefighting Equipment Maintenance Plan”, etc.</li>*/}
        {/*  <li>Implementation of vessel’s safety plans, setting-up of trainings on board and between the ship and the company;</li>*/}
        {/*  <li>Implementation and control of internal audits and possible deficiencies;</li>*/}
        {/*  <li>Control of familiarization of new crew members with the ship, their duties and safety procedures during operation or emergencies, Company’s Policy, etc.</li>*/}
        {/*  <li>Risk assessment of ship’s operation in order to minimize damages and accidents;</li>*/}
        {/*  <li>Annual assessment of Company’s Safety Management System by the senior management, implementation of measures directed at improvement of SMS level.</li>*/}
        {/*  <li>Implementation of International Ship and Port Facilities Security (ISPS) Code:</li>*/}
        {/*</ul>*/}
        {/*<h3>{localText.boxInfo.title2[lang]}</h3>*/}
        {/*<ul>*/}
        {/*  <li>Preparation of Ship Security Plan and approval by Class Society;</li>*/}
        {/*  <li>Vessel’s survey in accordance with ISPS Code requirements and issuance of International Ship Security Certificate.</li>*/}
        {/*</ul>*/}
      </div>
    </div>
  )
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: ${({ bgColor }) => (bgColor ? bgColor : "#f2f4f7")};
  margin-top: 30px;
  .flex {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    justify-content: flex-end;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    font-size: calc(16px + (26 - 16) * ((100vw - 300px) / (1600 - 300)));
    font-weight: normal;
    color: var(--grey);
    margin-top: ${({ bgColor }) =>
      bgColor === "transparent" ? "0px" : "initial"};
  }
  .tags-list {
    list-style: none;
    padding: 0;
    text-transform: uppercase;
  }
  .text {
    width: 100%;
    max-width: 100%;
    margin-top:0;
    ul {
      margin-bottom:20px;
      padding-left:30px;
      list-style: initial;
    }
    @media screen and (min-width: 1300px) {
      width: 75%;
    }
    @media screen and (min-width: 1600px) {
      width: 50%;
      padding: ${({ bgColor }) =>
        bgColor === "transparent" ? "0 50px" : "30px 60px"};
    }
    text-align: left;
    font-size: 16px;
  }
  .image {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    @media screen and (min-width: 1300px) {
      width:25%;
    }
    @media screen and (min-width: 1600px) {
      width: 50%;
    }
  }
`

const IsmPage = ({ pageContext, location }) => {
  const { lang: pLang, metaInfo, slug, otherLang, defLang, templateData } = pageContext

  const { lang, dispatch } = useContext(GlobalContext)
  // console.log("TEMPLATE DATA: ", templateData)
  useEffect(() => {
    dispatch({ type: "changeLanguage", payload: pLang })
  }, [pLang,dispatch])

  const ismTitle = templateData.sections[0].items[1].locales.find(l => l.iso === lang).value
  const ismBannerImage = templateData.sections[0].items[0].imageFile.publicURL

  const infoTitle = templateData.sections[1].items[0].locales.find(l => l.iso === lang).value
  const infoDescription = templateData.sections[1].items[1].locales.find(l => l.iso === lang).value

  const whatWeDoTitle = templateData.sections[2].items[0].locales.find(l => l.iso === lang).value
  const whatWeDoDescription = templateData.sections[2].items[1].locales.find(l => l.iso === lang).value
  const whatWeDoImage = getImage(templateData.sections[2].items[2].imageFile.childImageSharp.global)

  const helpTitle = templateData.sections[3].items[0].locales.find(l => l.iso === lang).value
  const helpDescription = templateData.sections[3].items[1].locales.find(l => l.iso === lang).value
  const btnLink = templateData.sections[3].items[2].slugs.find(l => l.iso === lang).value

  const footerImage = getImage(templateData.sections[4].items[0].imageFile.childImageSharp.footerCENTER)



  const data = useStaticQuery(graphql`
    query {
      contactFile: file(relativePath: { eq: "ismtopbanner.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
        publicURL
      }
      middleBannerFile: file(relativePath: { eq: "ismprovides.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 2.22)
        }
        publicURL
      }
      footerBannerFile: file(relativePath: { eq: "ismbannerfooter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 2.22)
        }
        publicURL
      }
    }
  `)
  // const image = getImage(data.file)
  // const subtitle = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend lorem quis neque finibus, vel efficitur nisl vestibulum. `
  return (
    <Layout
      location={location}
      metaInfo={metaInfo}
      lang={lang}
      slug={slug}
      ohterLang={otherLang}
      defLang={defLang}
    >
      <SectionBannerImage
        gradient
        noMargin
        title={ismTitle || "ISM/ISPS"}
        topBanner
        bgImage={ ismBannerImage || data.contactFile.publicURL}
        alt={`ism/isps`}
      />
      <SectionBox>
        <Anim>
          <SectionSeparator bgColor="linear-gradient(181deg, black 0%, lightgrey 150%)" />
        </Anim>
      </SectionBox>
      <DescriptionPart bgColor="#ab9a87">
        <h3>
          {infoTitle}
        </h3>
        <div dangerouslySetInnerHTML={{__html: infoDescription}} />
        {/*<p>*/}
        {/*  We provide you with an efficient, effective and easy to operate system*/}
        {/*  with minimal burden on the crew. 24-hour emergency and general support*/}
        {/*  is provided.*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*  Our service also includes consultancy assistance on all matters*/}
        {/*  relating to mandatory inspections such as Safety Certificates and*/}
        {/*  Class.*/}
        {/*</p>*/}
      </DescriptionPart>
      <SectionBannerImage
        noMargin
        image={getImage(data.middleBannerFile.childImageSharp)}
        alt="Section banner image"
      />
      <SectionBox>
        <h3 className="title">{whatWeDoTitle}</h3>
        <BoxInfo lang={lang} bgColor="transparent" text={whatWeDoDescription} image={whatWeDoImage}/>
      </SectionBox>
      <div style={{ borderTop: "10px solid #001f63", backgroundColor:"#f1f9ff" }}>
        <HelpSection>
          <h4>{helpTitle}</h4>
          <p>
            { helpDescription || localText.help.text[lang]}
          </p>
          <Button
            width="initial"
            bgColor="transparent"
            textColor="#776d61"
            padding="10px 35px"
            border="2px solid #ab9a87"
            margin="0"
          >
            <Link to={btnLink}>
              Contact us
            </Link>
          </Button>
        </HelpSection>
        <SectionBannerImage
          noMargin
          image={footerImage}
          alt="Section baner image"
        />
      </div>
    </Layout>
  )
}

export default IsmPage
